import { FC } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { SiMinutemailer } from "react-icons/si";

interface SubscribeUIProps {
  title: string;
  text: string[];
  onSubscribe: () => void;
  email: string;
  setEmail: (email: string) => void;
}

export const SubscribeUI: FC<SubscribeUIProps> = ({
  title,
  text,
  onSubscribe,
  email,
  setEmail,
}) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap="32px">
      <Typography variant="h4" component="h2">
        {title}
      </Typography>
      <Box component="div" textAlign="center" maxWidth="720px">
        {text.map((line, index) => (
          <Typography key={index} variant="body1" component="p">
            {line}
            <br />
          </Typography>
        ))}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        width="320px"
        boxShadow="0px 0px 0px 2px rgba(0.2, 0.2, 0.2, 0.0)"
      >
        <TextField
          fullWidth
          placeholder="Your email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            style: {
              backgroundColor: "#ffffff",
              borderRadius: "4px 0 0 4px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              flex: 5,
            },
          }}
        />
        <Button
          onClick={onSubscribe}
          disabled={!email}
          sx={{
            position: "relative",
            height: "55px",
            border: "none",
            backgroundColor: (theme) => theme.palette.primary.light,
            borderRadius: "0 4px 4px 0",
            cursor: "pointer",
            "&:disabled": {
              opacity: 0.5,
              pointerEvents: "none",
            },
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <SiMinutemailer
            className="icon-notsigned"
            style={{
              color: "white",
              fontSize: "25.8px",
              left: "50%",
              position: "absolute",
              top: "50%",
              transform: "translate(-50%, -50%)",
              transition: "300ms ease",
              opacity: 0.8,
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};
